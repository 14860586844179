<template>
    <v-col cols="12" >
        <v-simple-table class="tabla-receta">
          <thead>
            <tr>
              <th>CODIGO DE MONTURA</th>
              <th>NOMBRE MONTURA</th>
              <th>NOMBRE MEDIDA</th>
              <th class="px-15">OD ESFERA</th>
              <th class="px-15">OD CILINDRO</th>
              <th class="px-15">OD EJE</th>
              <th class="px-15">OI ESFERA</th>
              <th class="px-15">OI CILINDRO</th>
              <th class="px-15">OI EJE</th>
              <th class="px-15">DIP</th>
              <th class="px-15">ADICIÓN</th>
              <th class="px-15">FECHA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {{ localMedida.producto_codigo }} </td>
              <td> {{ localMedida.producto_nombre }} </td>
              <td>
                <v-text-field class="mt-6" dense label="Nombre" v-model="localMedida.medida_nombre" ></v-text-field>
              </td>
              <td>
                <v-select 
                  class="mt-6"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :items="resources.esferas"
                  label="ESFERA"
                  v-model="localMedida.medida_od_esfera"
                ></v-select>
              </td>
              <td>
                <v-select
                  class="mt-6"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :items="resources.cilindros"
                  label="CILINDRO"
                  v-model="localMedida.medida_od_cilindro"
                ></v-select>
              </td>
              <td>
                <v-select
                  class="mt-6"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :items="resources.ejes"
                  label="EJE"
                  v-model="localMedida.medida_od_eje"
                ></v-select>
              </td>
              <td>
                <v-select
                  class="mt-6"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :items="resources.esferas"
                  label="ESFERA"
                  v-model="localMedida.medida_oi_esfera"
                ></v-select>
              </td>
              <td>
                <v-select
                  class="mt-6"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :items="resources.cilindros"
                  label="CILINDRO"
                  v-model="localMedida.medida_oi_cilindro"
                ></v-select>
              </td>
              <td>
                <v-select
                  class="mt-6"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :items="resources.ejes"
                  label="EJE"
                  v-model="localMedida.medida_oi_eje"
                ></v-select>
              </td>
              <td>
                <v-select
                  class="mt-6"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :items="resources.dips"
                  label="DIP"
                  v-model="localMedida.medida_dip"
                ></v-select>
              </td>
              <td>
                <v-select
                  class="mt-6"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :items="resources.adiciones"
                  label="Adición"
                  v-model="localMedida.medida_adicion"
                ></v-select>
              </td>
              <td>
                <v-menu :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-6"
                      :value="formatFecha(localMedida.medida_fecha)"
                      dense
                      clearable
                      label="Fecha"
                      readonly
                      @click:clear="localMedida.medida_fecha = ''"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="localMedida.medida_fecha"
                    locale="es-es"
                  ></v-date-picker>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-btn
          :loading="processForm"
          class="float-right mt-2"
          color="success"
          @click="modificar"
        >
          <v-icon left="">mdi-check-bold</v-icon> Modificar
        </v-btn>

        <v-btn
          :loading="processForm"
          class="float-right mt-2 mr-2"
          color="error"
          @click="$emit('cerrar')"
        >
          <v-icon left="">mdi-close</v-icon> Cerrar
        </v-btn>
    </v-col>
</template>

<script>

export default {
    props: [ 'processForm', 'value' ],
    data() {
        return {
            resources: {
                esferas : [],
                cilindros : [],
                ejes : [],
                adiciones : [],
                dips : [],
                anios : [],
                meses : [],
            },
            localMedida: this.value,

        }
    },
    watch: {
        value(newValue){
            this.localMedida = newValue;
        }
    },
    methods: {
        formatFecha(fecha) {
            return fecha ? this.$moment(fecha).format("DD/MM/YYYY") : "";
        },
        modificar(){
            const newValue = {...this.localMedida}

            this.$emit('input', newValue);
            this.$emit('modificar', newValue);
        }
       
    },
    mounted() {
        const esferas    = [];  // -25.00  0.25  +20.00
        const cilindros  = [];  // 0.00    0.25  -8.00
        const ejes       = [];  // 0°      1     180°
        const adiciones  = [];  // +0.75   0.25  +4.00
        const distancias = [];  // 40 mm   1     80 mm

        for (let i = -25; i<=20; i += 0.25) {
            esferas.push({
            valor: i.toFixed(2),
            texto: (i>0 ? '+' : '')+i.toFixed(2)
            });

            if ( -8 <= i  && i < 0 ) {
                cilindros.push({
                valor: i.toFixed(2),
                texto: i.toFixed(2)
                });
            }

            if ( 0.75 <= i && i <= 4) {
                adiciones.push({
                valor: i.toFixed(2),
                texto: (i>0 ? '+' : '')+i.toFixed(2)
                });
            }

        }

        for (let i = 0; i <= 180; i++) {
            ejes.push({
            texto: i,
            valor: i
            });

            if ( 40 <= i && i <= 80) {
            distancias.push({
                texto: i,
                valor: i
            });
            }

        }


        this.resources.esferas    = esferas;
        this.resources.cilindros  = cilindros;
        this.resources.ejes       = ejes;
        this.resources.adiciones  = adiciones;
        this.resources.dips       = distancias;

        
        console.log(this.localMedida);
        
    }

}
</script>

<style>

</style>