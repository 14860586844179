<template>
  <div>
    <v-dialog v-model="openModal" max-width="1000" height="1000">
      <v-card class="pa-5">
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!mostrarContenido">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-show="mostrarContenido">
            <v-col cols="12">
              <v-select
                @change="modificarEstadoPago"
                v-model="idestado_pago"
                item-text="nombre"
                item-value="idestado_pago"
                :items="cmbEstadoPago"
                label="Estado Pago"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn class="mr-2" color="error" outlined="" @click="openModal = false">
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      idventa: "",
      idestado_pago: "",
      mostrarContenido: false,
      cmbEstadoPago: [],
    };
  },
  props: {
    objModalEstadoPago: {
      type: Object,
    },
  },
  watch: {
    objModalEstadoPago(val) {
      this.mostrarContenido = false;
      this.openModal = val.openModal;
      this.idventa = val.idventa;
      this.cmbEstadoPago = this.$parent.cmbEstadoPago;
      this.getEstadoPago();
    },
  },

  methods: {
    getEstadoPago() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/getEstadoPago",
        data: {
          idventa: this.idventa,
        },
      })
        .then((response) => {
          const venta = response.data.venta;
          this.idestado_pago = venta.pago_idestado_pago;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mostrarContenido = true;
        });
    },

    modificarEstadoPago() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/modificarEstadoPago",
        data: {
          idventa: this.idventa,
          idestado_pago: this.idestado_pago,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.openModal = false;

            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });

            this.$parent.listarRegistros();
          }
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al inhabilitar este registro", {
            icon: "mdi-close",
          });
        })
        .finally(() => {});
    },
  },
};
</script>
