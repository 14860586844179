<template>
  <v-col cols="12" >
      <v-row>
        <v-col cols="12" md="4" class="pt-0"><b>CODIGO DE MONTURA</b></v-col>
        <v-col cols="12" md="8" class="pt-0"> {{ localLcMedida.producto_codigo }} </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pt-0"><b>NOMBRE MONTURA</b></v-col>
        <v-col cols="12" md="8" class="pt-0"> {{ localLcMedida.producto_nombre }} </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pt-0"><b>NOMBRE MEDIDA</b></v-col>
        <v-col cols="12" md="8" class="pt-0">
          <v-text-field class="mt-0" dense outlined hide-details label="Nombre" v-model="localLcMedida.lc_medida_nombre" ></v-text-field>
        </v-col>
      </v-row>
          
      <v-row>
        <v-col cols="12" md="4" class="pt-0"><b>FECHA</b></v-col>
        <v-col cols="12" md="8" class="pt-0">
          <v-menu :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-0"
                :value="formatFecha(localLcMedida.lc_medida_fecha)"
                dense outlined hide-details clearable readonly
                label="Fecha"
                @click:clear="localLcMedida.lc_medida_fecha = ''"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="localLcMedida.lc_medida_fecha"
              locale="es-es"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>


        
      <v-row>
        <v-col cols="12" md="4" class="pt-0"><b>POWER</b></v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-select
            v-if="resources.poderes.length > 1"
            class="mt-0"
            dense outlined hide-details
            density="compact"
            :items="resources.poderes"
            label="POWER OI"
            v-model="localLcMedida.lc_medida_poder_oi"
            >
          </v-select>

          <v-text-field 
            v-else
            class="mt-0" 
            dense outlined hide-details 
            label="POWER OI"
            v-model="localLcMedida.lc_medida_poder_oi" 
            >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-select 
            v-if="resources.poderes.length > 1"
            class="mt-0"
            dense outlined hide-details
            :items="resources.poderes"
            label="POWER OD"
            v-model="localLcMedida.lc_medida_poder_od"
            >
          </v-select>

          <v-text-field 
            v-else
            class="mt-0" 
            dense outlined hide-details 
            label="POWER OD"
            v-model="localLcMedida.lc_medida_poder_od" 
            >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4" class="pt-0"><b>¿ASTIGMATISMO?</b></v-col>
        <v-col cols="12" md="8" class="pt-0">
          <v-switch
            class="mt-0"
            :false-value="0 || null"
            :true-value="1"
            dense hide-details
            v-model="localLcMedida.lc_medida_astigmatismo"
          ></v-switch>
        </v-col>
            
      </v-row>
        
      <v-row v-if="localLcMedida.lc_medida_astigmatismo">
        <v-col cols="12" md="4" class="pt-0"><b>CILINDRO</b></v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-select
            v-if="resources.cilindros.length > 1"
            class="mt-0"
            dense outlined hide-details
            :items="resources.cilindros"
            label="CYL OI"
            v-model="localLcMedida.lc_medida_cilindro_oi"
            >
          </v-select>

          <v-text-field 
            v-else
            class="mt-0" 
            dense outlined hide-details 
            label="CYL OI"
            v-model="localLcMedida.lc_medida_cilindro_oi" 
            >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-select
            v-if="resources.cilindros.length > 1"
            class="mt-0"
            dense outlined hide-details
            :items="resources.cilindros"
            label="CYL OD"
            v-model="localLcMedida.lc_medida_cilindro_od"
            >
          </v-select>

          <v-text-field 
            v-else
            class="mt-0" 
            dense outlined hide-details 
            label="CYL OD"
            v-model="localLcMedida.lc_medida_cilindro_od" 
            >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="localLcMedida.lc_medida_astigmatismo">
        <v-col cols="12" md="4" class="pt-0"><b>EJE</b></v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-select
            v-if="resources.ejes.length > 1"
            class="mt-0"
            dense outlined hide-details
            :items="resources.ejes"
            label="EJE OI"
            v-model="localLcMedida.lc_medida_eje_oi"
            >
          </v-select>

          <v-text-field 
            v-else
            class="mt-0" 
            dense outlined hide-details 
            label="EJE OI"
            v-model="localLcMedida.lc_medida_eje_oi"
            >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-select
          v-if="resources.ejes.length > 1"
            class="mt-0"
            dense outlined hide-details
            :items="resources.ejes"
            label="EJE OD"
            v-model="localLcMedida.lc_medida_eje_od"
            >
          </v-select>

          <v-text-field 
            v-else
            class="mt-0" 
            dense outlined hide-details 
            label="EJE OD"
            v-model="localLcMedida.lc_medida_eje_od"
            >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mt-3">
          <v-btn
            :loading="processForm"
            class="float-right"
            color="success"
            @click="modificar"
            >
            <v-icon left="">mdi-check-bold</v-icon> Modificar
          </v-btn>
    
          <v-btn
            :loading="processForm"
            class="float-right mr-2"
            color="error"
            @click="$emit('cerrar')"
            >
            <v-icon left="">mdi-close</v-icon> Cerrar
          </v-btn>
          
        </v-col>
      </v-row>

  </v-col>
</template>

<script>

export default {
  props: [ 'processForm', 'value' ],
  data() {
      return {
          resources: {
              poderes : [],
              cilindros : [],
              ejes : [],
          },
          localLcMedida: this.value,

      }
  },
  watch: {
      value(newValue){
          this.localLcMedida = newValue;
          this.setResources();
      }
  },
  methods: {
      formatFecha(fecha) {
          return fecha ? this.$moment(fecha).format("DD/MM/YYYY") : "";
      },
      modificar(){
        const newValue = {...this.localLcMedida}

        this.$emit('input', newValue);
        this.$emit('modificar', newValue);
      },
      setResources() {
        this.resources.poderes     = this.localLcMedida.producto_poder?.split(',').map( ele => ele.trim()) ?? [];
        this.resources.cilindros   = this.localLcMedida.producto_cilindroo?.split(',').map( ele => ele.trim()) ?? [];
        this.resources.ejes        = this.localLcMedida.producto_eje?.split(',').map( ele => ele.trim()) ?? [];
      }
     
  },
  mounted() {
    this.setResources();
  }

}
</script>

<style>

</style>